<template>
  <div class="w-full pt-5 pb-5">
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="flex">
        <back-button
          label="Back"
          @onClick="
            $router.push({ name: 'Leave', query: { type: $route.query.type } })
          "
          variant="secondary"
          class="mr-4"
        />
        <div
          class="mt-4"
          style="border-left: 1px solid #878E99; height:1.5rem"
        />

        <div style="">
          <h1 class="text-xl text-left font-extrabold capitalize ml-4 mt-3">
            {{ $route.query.name }}
          </h1>
        </div>

        <div class="w-7/12 mt-4 ml-4 capitalize">
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </div>
      <div class="px-3">
        <employee-detail :detail="employeeDetail" />
      </div>
      <div class="px-3">
        <card class="p-5" style="margin-top: 4px;">
          <div class="w-full flex justify-end ">
            <Button
              background-color="none"
              class="text-darkPurple ml-4 text-l"
              border="none"
              @click="openModal = true"
            >
              <div class="flex items-center">
                <icon
                  class-name="text-flame"
                  icon-name="icon-message-outline"
                  size="xsm"
                />
                <p class="ml-4 text-flame">
                  Comments
                </p>
              </div>
            </Button>
          </div>

          <div class="w-full border h-0 border-dashed" />

          <card-footer />

          <!----- List of KPA's ---->
        </card>

        <div class="flex">
          <Table
            :headers="leaveHeader"
            :items="leaveItems"
            class="w-full mt-4 mb-4"
            :loading="false"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.category">
                <span> {{ item.data.category.name }} </span>
              </div>
              <div v-if="item.requestReason" class="capitalize">
                <span> {{ item.data.requestReason }} </span>
              </div>
              <div v-else-if="item.daysRequested">
                <span class="text-sm font-semibold">
                  From {{ item.data.requestStartDate }} to
                  {{ item.data.requestEndDate }}
                </span>
                <p class="text-sm text-carrotOrange font-semibold">
                  {{ item.data.daysRequested }} Days
                </p>
              </div>
              <div v-else-if="item.allowanceIsRequested">
                <p v-if="item.data.allowanceIsRequested" class="text-sm">
                  Yes
                </p>
                <p v-else class="text-sm">
                  No
                </p>
              </div>
              <div v-if="item.delegatee" style="width: 230px" class="pt-3 pb-3">
                <div class="w-auto flex flex-col">
                  <p class="text-sm font-semibold text-darkPurple">
                    {{ item.data.delegatee.fname }}
                    {{ item.data.delegatee.mname }}
                    {{ item.data.delegatee.lname }}
                  </p>
                </div>
              </div>
              <div v-else-if="item.id">
                <icon
                  icon-name="more_icon"
                  size="xs"
                  class="-mt-1"
                  style="padding: 3px 10px; width: 36px; height: 36px;"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import EmployeeDetail from "../benefitUtilities/employeeDetail";

export default {
  name: "LeaveDetails",
  components: {
    BackButton,
    Breadcrumb,
    Icon,
    Card,
    Button,
    Table,
    CardFooter,
    EmployeeDetail
  },
  data() {
    return {
      leaveHeader: [
        { title: "Leave Type", value: "category" },
        { title: "Details", value: "requestReason" },
        { title: "Duration", value: "daysRequested" },
        { title: "Allowance Requested", value: "allowanceIsRequested" },
        { title: "Delegatee", value: "delegatee" }
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Benefits",
          id: ""
        },
        {
          disabled: false,
          text: "Leave",
          id: "Leave"
        },
        {
          disabled: false,
          text: this.$route.query.name,
          id: ""
        }
      ],
      leaveItems: [],
      employeeDetail: {},
      loading: true,
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight"
    };
  },
  methods: {
    async getEmployeeDetails() {
      try {
        const {
          data: { requests, employee, leaveStatus }
        } = await this.$_getEmployeeLeaveRequest({
          id: this.$route.params.id,
          status: this.$route.query.type
        });
        this.leaveItems = requests;
        this.employeeDetail = { ...employee, leaveStatus };
        this.loading = false;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  mounted() {
    this.getEmployeeDetails();
  }
};
</script>
